<script lang="ts" setup>
import {useFavicon} from '@vueuse/core'
import AppLogo from '@/components/layout/AppLogo.vue'

const { company } = useCompany()
const title = computed<string>(() => company.value ? company.value.name : 'Cargando...')

if (company.value?.favicon) {
  useFavicon(company.value.favicon)
}

useHead({
  htmlAttrs: {
    lang: 'es',
  },
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }
  ],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${title.value}` : title.value
  },
})
</script>

<template>
  <div class="bg-surface-50 dark:bg-surface-950 flex items-center justify-center min-h-screen min-w-[100vw] overflow-hidden">
    <div class="flex flex-col items-center justify-center">
      <div style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
        <div class="w-full bg-surface-0 dark:bg-surface-900 py-20 px-8 sm:px-20" style="border-radius: 53px">
          <app-logo class="cursor-pointer max-w-sm" @click="navigateTo({name: 'index'})" />

          <NuxtPage />
        </div>
      </div>
    </div>
  </div>

  <ConfirmDialog />
  <DynamicDialog />
  <Toast />
</template>
