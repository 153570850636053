<script lang="ts" setup>
import type Menu from 'primevue/menu'
import type {MenuItem} from "primevue/menuitem";

const $route = useRoute()
const {account, logout} = useAuth()
const menu = ref<typeof Menu>()

const toggleMenu = (event: MouseEvent) => {
  menu.value?.toggle(event)
}

const menuItems = computed<MenuItem[]>(() => {
  const items = []

  if (account.value?.is_staff) {
    const config = useRuntimeConfig()
    items.push(
      {
        command: () => navigateTo(config.public.adminURL, {
          external: true,
          open: {
            target: '_blank',
          }
        }),
        icon: 'pi pi-desktop',
        label: 'Administración'
      }
    )
  }

  items.push(
    {
      command: () => navigateTo({
        name: 'orders'
      }),
      icon: 'pi pi-list',
      label: 'Tus pedidos'
    },
    {
      command: () => navigateTo({
        name: 'account'
      }),
      icon: 'pi pi-user',
      label: 'Tu cuenta'
    },
    {
      command: () => logout($route.fullPath),
      icon: 'pi pi-sign-out',
      label: 'Cerrar sesión'
    }
  )

  return items
})
</script>

<template>
  <Button
    v-if="account"
    :label="account.name"
    icon="pi pi-user"
    rounded
    @click="toggleMenu"
  />
  <Menu
    :model="menuItems"
    popup
    ref="menu"
  />
</template>
