<script lang="ts" setup>
const {company} = useCompany()
const emit = defineEmits<{
  click: []
}>()
const props = defineProps<{
  class?: string
}>()
</script>

<template>

  <a v-if="company?.logo" @click="emit('click')">
    <img :src="company.logo" :alt="company.name" :class="props.class" />
  </a>
  <a v-else class="flex items-center"  @click="emit('click')">
    <span class="text-surface-900 dark:text-surface-0 font-medium text-2xl leading-normal mr-20">{{ company?.name }}</span>
  </a>
</template>
