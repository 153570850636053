
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as changebZ7QnYb3z9Meta } from "/tmp/build_fada16ff/pages/account/change.vue?macro=true";
import { default as index1Sqlh2PzOwMeta } from "/tmp/build_fada16ff/pages/account/index.vue?macro=true";
import { default as loginWvZzj2AkdQMeta } from "/tmp/build_fada16ff/pages/account/login.vue?macro=true";
import { default as _91token_93ker9jFAw70Meta } from "/tmp/build_fada16ff/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completed6rivHRMyJMeta } from "/tmp/build_fada16ff/pages/account/password-reset/complete.vue?macro=true";
import { default as donelFn0Hc38TpMeta } from "/tmp/build_fada16ff/pages/account/password-reset/done.vue?macro=true";
import { default as indexyCdTgTeOokMeta } from "/tmp/build_fada16ff/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetpkcAsyB1dwMeta } from "/tmp/build_fada16ff/pages/account/password-reset.vue?macro=true";
import { default as passworddw1KxDdVjCMeta } from "/tmp/build_fada16ff/pages/account/password.vue?macro=true";
import { default as registerYsMPvNL9pNMeta } from "/tmp/build_fada16ff/pages/account/register.vue?macro=true";
import { default as contactnx48naVNAdMeta } from "/tmp/build_fada16ff/pages/contact.vue?macro=true";
import { default as cookie_45policycEHtvxEBTZMeta } from "/tmp/build_fada16ff/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policymUjCHcxVrJMeta } from "/tmp/build_fada16ff/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45useQtqYAAmYVCMeta } from "/tmp/build_fada16ff/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_93j941QQrN91Meta } from "/tmp/build_fada16ff/pages/events/[slug].vue?macro=true";
import { default as indexcHxoPW2Q9OMeta } from "/tmp/build_fada16ff/pages/events/index.vue?macro=true";
import { default as indexIMN2OPiRuFMeta } from "/tmp/build_fada16ff/pages/index.vue?macro=true";
import { default as _91id_93t5bSPQtVRTMeta } from "/tmp/build_fada16ff/pages/orders/[id].vue?macro=true";
import { default as indexV7cQGd1PA1Meta } from "/tmp/build_fada16ff/pages/orders/index.vue?macro=true";
import { default as index97NuCakhx6Meta } from "/tmp/build_fada16ff/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: changebZ7QnYb3z9Meta || {},
    component: () => import("/tmp/build_fada16ff/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: index1Sqlh2PzOwMeta || {},
    component: () => import("/tmp/build_fada16ff/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginWvZzj2AkdQMeta || {},
    component: () => import("/tmp/build_fada16ff/pages/account/login.vue")
  },
  {
    name: password_45resetpkcAsyB1dwMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetpkcAsyB1dwMeta || {},
    component: () => import("/tmp/build_fada16ff/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_fada16ff/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_fada16ff/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_fada16ff/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_fada16ff/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passworddw1KxDdVjCMeta || {},
    component: () => import("/tmp/build_fada16ff/pages/account/password.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerYsMPvNL9pNMeta || {},
    component: () => import("/tmp/build_fada16ff/pages/account/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_fada16ff/pages/contact.vue")
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_fada16ff/pages/docs/cookie-policy.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_fada16ff/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_fada16ff/pages/docs/terms-of-use.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_fada16ff/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_fada16ff/pages/events/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_fada16ff/pages/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93t5bSPQtVRTMeta || {},
    component: () => import("/tmp/build_fada16ff/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexV7cQGd1PA1Meta || {},
    component: () => import("/tmp/build_fada16ff/pages/orders/index.vue")
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_fada16ff/pages/purchase/index.vue")
  }
]